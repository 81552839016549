<template>

</template>

<script>
import config from '../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

export default {
  name: 'reboot',
  created () {
    var confirmReboot = confirm("Reboot may stop all the running services. Are you sure to reboot the server?")
    if(confirmReboot){
      this.$router.push({ name: 'login' })
      this.$http.post(config.menu.host + 'auth/reboot')
    } else {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>